<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="70%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
          <a-row :gutter="24">
            <a-col :md="8" :xl="8" :xxl="8" :sm="20">
              <a-form-model-item label="商品名称">
                <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="20">
              <a-form-model-item label="商品分类">
                <a-cascader
                  :options="categoryList"
                  :value="categorySelectData"
                  style="width: 100%"
                  :field-names="{ label: 'name', value: 'id', children: 'children' }"
                  placeholder="请选择"
                  @change="onCategoryChange"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="6" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="商品品牌" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
                <a-select v-model="searchData.brandId" placeholder="请选择商品品牌">
                  <a-select-option :value="item.id" v-for="(item, index) in proBrandList" :key="index">
                    {{ item.ch_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="20">
              <a-form-model-item label="标准开票价（D价）">
                 <a-input-number placeholder="开始" v-model="searchData.startPrice" :min="1" :max="999999" />
                 <a-input-number placeholder="结束" v-model="searchData.endPrice" :min="1" :max="999999" />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table
          :data-source="tableData"
          :columns="columns"
          :loading="tableLoading"
          :rowKey="(record) => record.id"
          :pagination="page"
          bordered
          @change="changeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <span slot="imgUrl" slot-scope="item">
            <ZoomMedia style="height: 50px" :value="item"></ZoomMedia>
          </span>
          <span slot="price" slot-scope="item">
            {{ item.toFixed(2) }}
          </span>
          <span slot="sellPrice" slot-scope="item">
            {{ item.toFixed(2) }}
          </span>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
      </div>
      <!--      <ConfirmProduct ref="ConfirmProduct" :dataList="dataList" :dealerId="dealerId"></ConfirmProduct>-->
    </a-modal>
  </div>
</template>

<script>
import ConfirmProduct from './ConfirmProduct.vue'
export default {
  components: {
    ConfirmProduct,
  },
  data() {
    return {
      visible: false,
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      columns: [
        {
          title: '商品图标',
          dataIndex: 'imgUrl',
          // height: 50,
          ellipsis: true,
          align: 'center',
          key: 'imgUrl',
          scopedSlots: { customRender: 'imgUrl' },
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          // width: 200,
          ellipsis: true,
          align: 'center',
        },
        {
          title: '分类',
          dataIndex: 'categoryName',
          // width: 120,
          ellipsis: true,
          align: 'center',
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          // width: 120,
          ellipsis: true,
          align: 'center',
        },
        {
          title: '建议零售价',
          dataIndex: 'price',
          // width: 120,
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'price' },
        },

        {
          title: '标准开票价（D价）',
          dataIndex: 'sellPrice',
          // width: 120,
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'sellPrice' },
        },
        {
          title: '单位',
          dataIndex: 'proUnitName',
          // width: 120,
          ellipsis: true,
          align: 'center',
        },
      ],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      selectedRowKeys: [],
      selectedRows: [],
      dealerId: '',
      dataList: [],
      current: 0,
      steps: [
        {
          title: '第一步',
          content: '请选择商品',
        },
        {
          title: '第二步',
          content: '请选择政策',
        },
      ],
      policyList: [],
      policyKeysList: [],
      list: [],
      categoryList: [],
      categoryValue: [],
      categorySelectData: [],
      proBrandList: [],
    }
  },
  created() {
    this.axios.get('/api/product/category/categoryList?flagEnable=true').then((res) => {
      this.categoryList = res.body[0] ? res.body[0].children : []
    })
    this.axios.get('/api/product/system/brand/All/list').then((res) => {
      this.proBrandList = res.body
    })
  },
  methods: {
    onCategoryChange(value) {
      console.log(value)
      this.categorySelectData = value
      var reg = new RegExp(',', 'g')
      this.searchData.categoryIdStr = value
        .map((i) => {
          return i
        })
        .toString()
        .replace(reg, '_')
    },
    // 打开弹框是触发
    isShow(id, list) {
      this.visible = true
      this.dealerId = id
      this.list = list
      this.getList(0)
    },
    // 获取数据源
    getList(num) {
      this.tableLoading = true
      if (num === 1) {
        this.searchData = {}
        this.categorySelectData = []
      }
      const data = Object.assign(
        {},
        {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          productStatus: 3,
          ...this.searchData,
        }
      )
      console.log(data)
      this.axios.get('/api/product/system/goods/list', { params: data }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.body.records
          this.tableLoading = false
        }
      })
    },
    // 关闭弹框
    onCancel() {
      this.visible = false
    },
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onSelectedChange(selectedRowKeys, selectedRows) {
      this.policyKeysList = selectedRowKeys
      this.policyList = selectedRows
    },
    // 监听当前选中数据
    onPolicySelectChange(val, data) {
      console.log('val----', val)
      console.log('data----', data)
    },
    // 步骤条下一步
    next() {
      this.onSubmit()
    },
    // 步骤条上一步
    prev() {
      this.current--
    },
    onConfirm() {},
    // 获取政策列表
    onSubmit() {
      let ids = []
      if (this.list.length > 0) {
        let item = this.list.map((e) => e.goodsId)
        ids = this.selectedRowKeys.filter((i) => !item.some((e) => e === i))
      }
      let tmpIds = ids.concat(this.selectedRowKeys)
      let res = new Map()
      ids = tmpIds.filter((e) => !res.has(e) && res.set(e, 1))
      //
      let data = {
        dealerId: this.$store.getters.userInfo.dealerId,
        goodsIds: ids,
      }
      if (this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      // if(data.goodsIds.length <= 0) {
      //   return this.$message.warning('当前商品已添加')
      // }
      this.btnsLoading = true
      this.axios.post(`/api/order/order/orderInfo/queryProListAndPolicy`, data).then((res) => {
        if (res.code === 200) {
          this.dataList = res.body
          this.visible = false
          this.$emit('get-product-list', this.dataList)
          this.btnsLoading = false
        }
      })
      this.selectedRowKeys = []
    },
  },
}
</script>

<style scoped>
.steps-action {
  margin-top: 20px;
  text-align: right;
}
.steps-content {
  margin-top: 60px;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>